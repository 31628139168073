$(document).ready(function(){

  toggleKeywordInput($('#location'));

  $('body').on('change', '#location', function(){
    toggleKeywordInput($(this));
    retainSearchedValue($(this));
  });

  $('body').on('change', '#doctor_gender', function(){
    $('#doctor-secondary-search-form').submit();
  });

});

function toggleKeywordInput(elem){
  var val = $(elem).val();
  if(val != ''){
    $('#keywords').attr('disabled', false);
  }else{
    $('#keywords').attr('disabled', true);
  }
}

function retainSearchedValue(elem){
  var val = $(elem).val();
  if(val == ''){
    var searchedText = $('#location').data("search-text");
    if(searchedText != ''){
      $(elem).val(searchedText);
      toggleKeywordInput($(elem));
    }
  }
}