require("@rails/ujs").start()
require('jquery')
// import Turbolinks from "turbolinks"

// Turbolinks.start()

window.jQuery = $;
window.$ = $;
require("datatables.net-bs4");
// require('medicare/bootstrap_from_consumer.min.js');
require("medicare/bootstrap.bundle.min.js");
require("medicare/jquery.blockUI.js");
require('medicare/jquery.bcSwipe_from_consumer.min.js');
require('plugins/inputmask/jquery.inputmask.min');
require('plugins/autonumeric/autoNumeric-min.js');
require("@nathanvda/cocoon");
require("medicare/common_script.js");
require("medicare/plans.js");
require("medicare/multi_form.js");
require("medicare/applications.js");
require("medicare/drugs.js");
require("medicare/app.js");
require("medicare/dashboard.js");
require("medicare/profile.js");
require("medicare/time_selection.js");
require("medicare/notes.js");
require("medicare/drugs_search.js");
require("medicare/client_request.js");
require("medicare/doctors.js");

import "stimulus"
import 'controllers'
